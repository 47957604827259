import { Menu } from "../models/menu.model";

export const MENU: Array<Menu> = [
  {
    route: '/dashboard',
    title: 'Inicio',
  },
  {
    title: 'Documentos',
    children: [
      {
        route: '/dashboard/documentos/general-balance',
        title: 'Balance general',
      }
    ]
  },
  {
    title: 'REDECO',
    children: [
      {
        route: '/dashboard/condusef/redeco/complaints',
        title: 'Quejas',
      }
    ]
  },
  {
    title: 'REUNE',
    children: [
      {
        route: '/dashboard/condusef/reune/general-consult',
        title: 'Consultas generales',
      },
      {
        route: '/dashboard/condusef/reune/claims',
        title: 'Reclamaciones',
      },
      {
        route: '/dashboard/condusef/reune/remarks',
        title: 'Aclaraciones',
      }
    ]
  },
  {
    title: 'Usuarios',
    children: [
      {
        route: '/dashboard/users',
        title: 'Porvenir',
      },
      {
        route: '/dashboard/condusef/users',
        title: 'Condusef',
      },
    ]
  },{
    title: 'Contabilidad',
    children: [{
      route: '/dashboard/accounting/get-closing',
      title: 'Cierre de mes'
    },{
      route: '/dashboard/accounting/statement-account',
      title: 'Estado de cuenta'
    }]
  }
  
];